
$colloumn-width-left: 50px;
$header-height: 70px;

// colors:
$header-color: #222222;
$background: #282C34;
$light-grey: #42464D;
$lighter-grey: #707070;
$cyan: #61DAFB;
$yellow: #FAC863;
$red: #FC929E;
$green: #8DC891;
$lila: #C5A5C5;
$white: #FFFFFF;

$secondaryText: #909090;

$time-offset: 75px;
$time-height: 60px;

body{
  font-family: "Segoe UI",sans-serif;
  font-size: 16px;
  color: white;
  margin: 0px;
  font-weight: 300;
  background-color: $background;
}

.App-header {
  width: 100%;
  height: $header-height;
  padding: 0px;
  background-color: $header-color;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);

  .Title {
    margin-left: $colloumn-width-left;
    margin-top: 0px;
    margin-bottom: 0px;
    color: $cyan;
    font-size: 25px;
  }
}

.dayContainer{
  display: flex;
}

.timetable{
  position: relative;
  float: left;
  width: 100%;
}

.day {
  position: absolute;
  top: 4px;
  left: 0px;
  text-align: center;
  width: $colloumn-width-left;
  color: $yellow;
  
  .number{
    margin: 0px;
    font-size: 30px;
  }

  .short{
    margin: 0px;
    font-size: 14px;
  }
}

.hour {
  height: $time-height/2;
  width: 100%;

  .number {
    position: absolute;
    color: $white;
    font-size: 14px;
    text-align: right;
    width: $colloumn-width-left - 10px;
  }


  .line{
    position: relative;
    left: $colloumn-width-left;
    border: #707070;
    top: 8px;
    border-top-style: solid;
    border-top-width: 1px;
    height: 0px;
    width: calc(100% - #{$colloumn-width-left});
  }
}
.hideTime{
  .line{
    border-color: #505050;
    left: $colloumn-width-left + 5px;
    width: calc(100% - #{$colloumn-width-left + 5px});
  }
  .number{
    // font-size: 12px;
  }
}

.halfHour.hideTime{
  .number{
    // display: none;
  }
}

.fullHour{
  .number{
    // margin-top: -4px;
    // font-size: 17px;
    text-align: right;
  }
}


.hideTime{
  .number{
    color: $secondaryText;
  }
}

.hours{
  position: relative;
  margin-top: 73px;
}

.courseContainer{
  position: absolute;
  top: $time-offset + 8px;
  left: $colloumn-width-left;
  margin-left: 11px;
  margin-right: 7px;
  width: calc(100% - #{$colloumn-width-left} - 11px);
}


.canceled{
  border-color: mix($red, $light-grey, 25%);
  //text-decoration: line-through $white;
  background: repeating-linear-gradient(
    -45deg,
    mix($red, $light-grey, 25%),
    mix($red, $light-grey, 25%) 10px,
    $light-grey 10px,
    $light-grey 20px
  );
  .title {
    text-decoration: line-through $white;
  }
}

.course{
  position: absolute;
  background-color: $light-grey;
  border-color: $lighter-grey;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
}

.firstLine{
  display: flex;
  font-size: 18px;
  justify-content: space-between;

  .title{
    // color: $white;
    // color: $green;
    // color:$yellow;
  }
}

.type{
  color: $secondaryText;
  padding-left: 3px;
}

.location{
  color: $green;
  //color: $secondaryText;
  padding-left: 3px;
}

.time{
  // color: $red;
  color: $secondaryText;
  // padding-left: 3px;
  font-size: 12px;
}

.description,.anmerkung{
  padding-left: 3px;
  font-size: 12px;
  color: $lila;
  font-size: 12px;
}

.currentTime {
  margin-top: $time-offset + 8px;
  position: absolute;
  width: 100%;
  transition: transform 1s ease-out;
  
  .line{
    width: calc(100% - #{$colloumn-width-left * 0.9});
    height: 0px;
    border-top: solid;
    border-top-color: $cyan;
    border-top-width: 2px;
    margin-left: $colloumn-width-left * 0.9;
  }

  .point{
    $size: 10px;
    width: $size;
    height: $size;
    border-radius: $size / 2;
    background-color: $cyan;
    margin-left: $colloumn-width-left * 0.9 - $size / 2;
    margin-top: (-$size / 2) - 1px;
  }
}

.informationContainer{
  position: relative;
}

.information{
  color: $white;
  position: absolute;
  top: 0px;
  left: $colloumn-width-left;
}

.statusContainer{
  position: absolute;
  top: 0px;
  right: 70px;
  height: $header-height;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $white;
}

.warningIcon{
  fill: $yellow;
}

.SettingsIcon{
  fill:$lighter-grey;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}

.Settings{
  position: absolute;
  right:20px;
  height:70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.doneIcon{
  fill:$lighter-grey;
}

.daysContainer{
  width: 100%;
}

.dayContainer.left {
  left: -100%;
}
.dayContainer.right {
  left: 100%;
}

.warning{
  color: white;
  padding: 10px;
  width: auto;
  background-color: $lighter-grey;
  border: #FAC863;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  margin-bottom: 20px;

  a, a:visited {
    color: $yellow;
    white-space: nowrap;
  }
}

.warningText{
  padding-top: 0px;
  line-height: 1.2;
}

input {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background-color: $lighter-grey;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  color: $white;
}

button{
  height: 40px;
  font-size: 16px;
  padding: 0px;
  border: none;
  transition: all 250ms ease-in-out;
}

button:disabled{
  opacity: 0.5;
}

.login{
  padding: 20px 15px 0px 15px;

  .checkboxContainer{
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .actions{
    margin-top: 10px;
    display: flex;
    align-content: stretch;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;

    .action{
      flex: 1 1 100px;
    }

    .main{
      background-color: $cyan;
      color: $white;
      margin-left: 5px;
    }

    .sec{
      background-color: transparent;
      color: $white;
      margin-right: 5px;
      border: solid;
      border-width: 1px;
      border-color: $cyan;
      color: $cyan;
    }
  }

  input::placeholder{
    font-size: 16px;
    color: #A7A7A7;
  }
}

.checkboxContainer{
  display: flex;

  
  input{
    outline: none;
    appearance: none;
    flex: 0 0 auto;
    padding: 0px;
    margin:  5px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    position: relative;
  }

  input:checked {
    background-color: $cyan;
  }

  input:after {
    transition: 250ms ease-out;
  }
  input:before {
    transition: 250ms ease-out;
  }

  $posLeft: 12px;
  $posTop: 4px;
  $color: #FFFFFF;
  $width: 8px;
  $height: 18px;
  $borderWidth: 3px;
  input:not(:checked):after {
    content: "";
    background-color: transparent;
    
    /* position the checkbox */
    position: absolute;
    left: $posLeft;
    top: $posTop;
  
    /* setting the checkbox */
      /* short arm */
    width: $height;
      /* long arm */
    height: $height;
    border-right: $borderWidth solid $color;
    
    /* rotate the mirrored L to make it a checkbox */
    transform: translate(-10px, -3px) rotate(45deg);
    box-sizing: border-box;
  }
  input:not(:checked):before {
    content: "";
    background-color: transparent;
    
    /* position the checkbox */
    position: absolute;
    left: $posLeft;
    top: $posTop;
  
    /* setting the checkbox */
      /* short arm */
    width: $height;
    border-bottom: $borderWidth solid $color;
      /* long arm */
    height: $height;
    
    /* rotate the mirrored L to make it a checkbox */
    // height = width * a
    transform:  translate(0px, -3px) rotate(45deg);
    box-sizing: border-box;
  }

  input:checked:after {
    content: "";
    background-color: transparent;
    
    /* position the checkbox */
    position: absolute;
    left: $posLeft;
    top: $posTop;
  
    /* setting the checkbox */
      /* short arm */
    width: $width;
      /* long arm */
    height: $height;
    border-right: $borderWidth solid $color;
    
    /* rotate the mirrored L to make it a checkbox */
    transform: rotate(40deg);
    box-sizing: border-box;
  }
  input:checked:before {
    content: "";
    background-color: transparent;
    
    /* position the checkbox */
    position: absolute;
    left: $posLeft;
    top: $posTop;
  
    /* setting the checkbox */
      /* short arm */
    width: $width;
    border-bottom: $borderWidth solid $color;
      /* long arm */
    height: $height;
    
    /* rotate the mirrored L to make it a checkbox */
    transform: rotate(40deg);
    box-sizing: border-box;
  }
  
  div {
    flex: 1 1 auto;

    margin-top: auto;
    margin-bottom: auto;

    b {
      color: $yellow;
    }
  }
}

.courseDetail{
  padding: 20px 15px 0px 15px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.section{
  margin-top: 31px;

  .title {
    font-size: 22px;
    color:$yellow;
    margin-bottom: 14px;
  }
}
.coursePreview {
  max-width: 500px;
  .course{
    position: relative;
    width:auto;
    top: 0px;
    left: 0px;
  }

  .element{
    width:auto;
  }
}

.settingsview {
  display: grid;
  grid-template-columns: minmax(auto, 20%) 1fr;
  grid-column-gap: 10px;

  .element{
    padding-bottom: 9px;
  }

  .key{
    overflow-wrap: break-word;
    color: $lila;
  }

  .vale{
    font-style: italic;
  }
}

a, a:visited {
  color: $cyan;
}

.created{
  margin-top: 50px;
  position: absolute;
  bottom: 5px;
}

.dayCarousel{
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.dayContainer{
  flex: 0 0 100%;
  scroll-snap-align: start;
}